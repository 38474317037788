body {
  font-family: "Arial", serif !important; /* Assicurati di includere "sans-serif" come fallback */
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
}

.t-success {
  color: #1F6358 !important;
}

.text-shadow {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.secondary-color {
  color: #024C44 !important;
}

.tertiary-color {
  color: #07ABB8 !important;
}


/* Per schermi più larghi di 900px */
@media (min-width: 901px) {
  .zoomed {
    padding-top: 1rem;
    padding-bottom: 1rem;
    transform: scale(1.6)
  }

  .font12 {
    font-size: 16px
  }

  .font14 {
    font-size: 18px
  }

  .font20 {
    font-size: 22px
  }

}

.font10 {
  font-size: 10px
}

.font12 {
  font-size: 12px
}

.font14 {
  font-size: 14px
}

.font15 {
  font-size: 15px
}

.font20 {
  font-size: 20px
}

.font25 {
  font-size: 25px
}

.font35 {
  font-size: 35px
}

.font85 {
  font-size: 85px
}

.title {
  font-family: Arial, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  color: #024C44;
}

.card-title{
  font-family: Arial, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
}

.field-title{
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.4px;
  text-align: left;
}

.card-body{
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
}

.field-body{
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
}

.description{
  font-family: Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
}

.title18 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  font-size: 18px;
  font-weight: 500;
  color: #024C44;
  line-height: 1; /* Modifica questo valore secondo le tue esigenze */
}

