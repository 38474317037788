//Rimuovo l'occhio che compare con Edge nei campi Password (Che compare ovviamente solo con Edge ovviamente, grazie Edge, sei epico Edge, mannaggia a chi ti usa Edge)
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

::-webkit-scrollbar {
  width: 0;  /* rende la scrollbar invisibile in larghezza */
  height: 0; /* rende la scrollbar invisibile in altezza */
}

::-webkit-scrollbar-thumb {
  background-color: transparent; /* rende il thumb della scrollbar invisibile */
}

::-webkit-scrollbar-track {
  background-color: transparent; /* rende la traccia della scrollbar invisibile */
}

img {
  user-select: none;
  pointer-events: none;
}

ngb-modal-backdrop {
  z-index: 99999 !important;
}

ngb-modal-window {
  z-index: 999999 !important;
}

.agos-check-input[type="checkbox"] {
  background-color: #024C44 !important;
}

.min-h-100 {
  min-height: 90% !important;
}

.form-check-input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #024C44;
  outline: none;
  cursor: pointer;
}

.form-check-input[type="radio"]:checked {
  background-color: #024C44;
}

.form-check-input[type="radio"]:checked:disabled {
  background-color: #999;
}

.form-check-input[type="radio"]:disabled {
  background-color: #ccc;
}

.form-check-input[type="radio"]:disabled::before {
  background-color: #ccc;
}


.box {
  border-radius: 20px;
  padding: 20px;
  background-color: #E6E6E6;
}

.outline-box {
  border: 1px solid black;
  border-radius: 20px;
  padding: 20px;
}

.list-number {
  background-color: #07ABB8;
  color: white;
  font-size: 23px;
  font-weight: 500;
  text-align: center;
  border-radius: 100%;
  width: 35px;
  height: 35px;
}

.agos-form {
  background-color: #E6E6E6 !important;
  border-radius: 10px;
  height: 80px;
  border: none;
  padding-left: 1rem;
  padding-top: 1.5rem;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #E6E6E6 inset !important; /* Imposta il colore dello sfondo durante l'autocompletamento */
  -webkit-text-fill-color: #495057 !important; /* Imposta il colore del testo durante l'autocompletamento */
}

.form-error {
  border-bottom: red 2px solid !important;
}

.form-success {
  border-bottom: #1F6358 2px solid !important;
}

.error-tooltip {
  position: relative;
  margin-left: 0;
  width: 100%;
  padding-left: 20%;
}

.error-tooltip .tooltip-content {
  visibility: visible;
  border: 1px solid #FF0000;
  background-color: #F8DEDE;
  color: #FF0000;
  text-align: end;
  border-radius: 6px;
  padding: 5px;
  z-index: 1;
  top: 15px;
  right: 0;
  width: auto;
}

.error-tooltip .arrow {
  margin-left: calc(100% - 40px);
  width: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ff6961; /* Colore rosso per danger */
}

.ph-caret-right {
  transform: translateY(3px);
}

.bank-search {
  background-color: #E6E6E6 !important;
  border-radius: 10px;
  border: none;
  padding: 5px
}
// FIXME Uhuh, bank-search duplicato, suppongo una delle due sia più corretta dell'altra ma non ricordo quale :^)
.bank-search {
  background-color: #E6E6E6 !important;
  border: none;
  border-radius: 25px 0px 0px 25px;
  padding: 10px 10px 10px 1rem;
  outline: none !important;
}

.bank-icon {
  background-color: #E6E6E6 !important;
  border-radius: 0px 25px 25px 0px;
  border: none;
  padding: 10px;
  outline: none !important;
}

.form-check-input:focus {
  color: #07ABB8!important;
  border-color: transparent!important;
  outline: none!important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  color: #024C44!important;
  background-color: #024C44!important;
  border-color: #024C44!important;
}

.form-floating > .form-control:focus ~ label::after, .form-floating > .form-control:not(:placeholder-shown) ~ label::after, .form-floating > .form-control-plaintext ~ label::after, .form-floating > .form-select ~ label::after {
   background-color: transparent!important;
}

.form-floating > .form-control:focus ~ label::after, .form-floating > .form-control:not(:placeholder-shown) ~ label::after, .form-floating > .form-control-plaintext ~ label::after, .form-floating > .form-select ~ label::after {
  background-color: transparent!important;
}