@media (max-width: 800px) {
  .col-md-6 {
    width: 100% !important;
  }
}

.agos-background {
  background-color: #07ABB8
}

.agos-secondary-background {
  background-color: #E6E6E6;
}

.title-secondary {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  font-size: 28px;
  font-weight: 500;
  color: #5D5D5D;
  line-height: 1; /* Modifica questo valore secondo le tue esigenze */
}

