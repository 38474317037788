.btn-link {
  color: #024C44 !important;
}

.btn-link:active {
  color: #168073 !important;
  text-decoration: none !important;
}

.btn-outline-link {
  color: black;
  text-decoration: underline !important;
  border: none !important;
}

.btn-outline-link:active {
  color: #4b4b4b !important;
  text-decoration: none !important;
}

.agos-button {
  border-radius: 20px !important;
  min-width: 170px !important;
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #024C44 !important;
  font-weight: 700 !important;
}

.agos-button:active {
  background-color: #D8F7DBCC !important;
  color: #5D5D5D !important;
  box-shadow: 0px 4px 8px 0px #00000040 inset;
}

.agos-button:disabled {
  opacity: 100% !important;
  background-color: #E6E6E6 !important;
  color: #5D5D5D !important;
  border: 1px solid #5D5D5D;
}

.agos-outline-button {
  border-radius: 20px !important;
  width: 150px !important;
  padding: 7px 15px 7px 15px !important;
  border: 1px solid #024C44 !important;
  color: #024C44 !important;
  background-color: white !important;
  font-weight: 700 !important;
}


.agos-outline-button:active {
  box-shadow: 0px 4px 8px 0px #00000040 inset;
}

.agos-outline-button:disabled {
  background-color: #E6E6E6;
  color: #5D5D5D
}

.method-button {
  padding: 15px !important;
  border-radius: 10px !important;
  gap: 5px !important;
  background: #E6E6E6 !important;
  border: 1px solid transparent !important;
}

.method-button:hover {
  border: 1px solid black !important;
}

.selected {
  border: 1px solid #07ABB8 !important;
  background: #E6F4F7 !important;
}

.ph-modal {
  position: absolute!important;
  cursor: pointer!important;
  margin-top: -15px!important;
  margin-left: calc(100% - 70px) !important;
}
